import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import useForm from '../hooks/useForm'

const Form: React.FC = () => {
  const { loading, error, success, submit } = useForm()

  const [name, setName] = useState('Anonymous')
  const [message, setMessage] = useState('')
  const [token, setToken] = useState<string | null>(null)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    submit(name, message, token)
  }

  return (
    <Box py={1}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">
          Something went wrong and your message was not received. Please refresh
          the page and try again.
        </Alert>
      ) : success ? (
        <Alert severity="success">Your message was received, thank you!</Alert>
      ) : (
        <Box component="form" autoComplete="off" onSubmit={handleSubmit} pt={1}>
          <Box mb={2}>
            <TextField
              label="Name (optional)"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              minRows={6}
              sx={{ width: '100%' }}
              required
            />
          </Box>
          <Box mb={2}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              onChange={setToken}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={!message || !token}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  )
}
export default Form
