import { Box, Container, Typography } from '@mui/material'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'
import React from 'react'
import Form from './components/Form'

let theme = createTheme()
theme = responsiveFontSizes(theme)

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <Container maxWidth="md">
      <Box py={3}>
        <Typography variant="h2" fontWeight="bold" gutterBottom>
          Hi 👋
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can send me anonymous feedback using the form below :)
        </Typography>
        <Form />
      </Box>
    </Container>
  </ThemeProvider>
)

export default App
