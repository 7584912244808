import { useState } from 'react'

const useForm = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const submit = async (
    name: string,
    message: string,
    token?: string | null,
  ) => {
    setLoading(true)
    setError(false)
    setSuccess(false)

    const result = await fetch('/.netlify/functions/messages', {
      method: 'POST',
      body: JSON.stringify({ name, message, token }),
    })

    await new Promise((r) => setTimeout(r, 1000))

    setLoading(false)

    if (result.status !== 204) {
      setError(true)
    } else {
      setSuccess(true)
    }
  }

  return { loading, error, success, submit }
}

export default useForm
